import React from 'react'
import { ExperienceDataInterface } from '../interfaces'

export default function ExperienceCard({ data }: { data: ExperienceDataInterface }): JSX.Element {
  const finalDescription = data.description.split('\n').map((str: string) => <p key={str}>{str}</p>)

  return (
    <div className="experienceCard">
      <h4>{data.name}</h4>
      <h5>{data.title}</h5>

      <div className="descriptionContainer">{finalDescription}</div>
    </div>
  )
}
